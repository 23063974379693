import React from "react";
import s490 from "../../assets/catalogue/machine1.jpg";
import s630 from "../../assets/catalogue/machine_2.JPG";
import eb230 from "../../assets/catalogue/machine3.JPG";
import x1080 from "../../assets/catalogue/X1080.jpg";
import x9000 from "../../assets/catalogue/x9000.png";
import X360 from "../../assets/catalogue/360.png";
import X720 from "../../assets/catalogue/720.png";
import X540 from "../../assets/catalogue/X540.png";
import fastshot from "../../assets/catalogue/fastshot.jpeg";

function SprayMachines() {
  return (
    <div>
      <h1 id="pg_title">PAINT SPRAYERS</h1>
      <p id="hoses_p">
        For high production rates and top performance, count on durable,
        easy-to-maintain Pradco battery and electric airless sprayers.
      </p>
      <br />
      <br />
      <div className="ps_cards">
        {/* CARD */}
        <div className="s_card">
          <h3>ELECTRIC SPRAYER</h3>

          <img src={s490} alt="SprayX 490" />
          <h3>SprayX - 490</h3>
          <p>
            Power : 2 HP <br />
            Pressure : 230 bar <br />
            Flow Rate : 2.5 LPM
            <br />
            Max Tip Size : 0.023 <br />
            Materials : Primer / Paint / Foam <br />
          </p>
        </div>

        <div className="s_card">
          <h3>ELECTRIC SPRAYER</h3>

          <img src={s630} alt="SprayX 490" />
          <h3>SprayX - 630</h3>
          <p>
            Power : 3 HP <br />
            Pressure : 230 bar <br />
            Flow Rate : 4 LPM
            <br />
            Max Tip Size : 0.028 <br />
            Materials : Primer / Paint / Foam <br />
          </p>
        </div>

        <div className="s_card">
          <h3>ELECTRIC SPRAYER</h3>

          <img src={eb230} alt="SprayX 490" />
          <br />
          <br />
          <h3>SprayX - 900</h3>
          <p>
            Power : 6 HP <br />
            Pressure : 230 bar <br />
            Flow Rate : 10 LPM
            <br />
            Max Tip Size : 0.054 <br />
            Materials : Primer / Paint / Putty / PU / Foam /Epoxy / Red Oxide /
            White Cement <br />
          </p>
        </div>
      </div>

      <div className="ps_cards">
        {/* CARD */}
        <div className="s_card">
          <h3> BATTERY SPRAYER</h3>
          <img id="shot" src={fastshot} alt="SprayX Fast shot xpro" />
          <h3>SprayX - Fast Shot Pro</h3>
          <p>
            Power : 2 HP <br />
            Pressure : 230 bar <br />
            Flow Rate : 2 LPM
            <br />
            Max Tip Size : 0.023 <br />
            Materials : Primer / Paint / Foam <br />
          </p>
        </div>

        <div className="s_card">
          <h3>HYBRID SPRAYER (Electric + Petrol)</h3>
          <img id="x630" src={x1080} alt="SprayX 1080" />
          <h3>SprayX - 1080</h3>
          <p>
            Power : 6 HP <br />
            Pressure : 230 bar <br />
            Flow Rate : 10 LPM
            <br />
            Max Tip Size : 0.054 <br />
            Materials : Primer / Paint / Putty / PU / Foam /Epoxy / Red Oxide /
            White Cement
            <br />
          </p>
        </div>

        <div className="s_card">
          <h3>ELECTRIC REACTOR</h3>
          <img src={x9000} alt="SprayX 9000" />
          <h3>SprayX - 9000</h3>
          <p>
            Power : 3Phase 3 wire 380V 50 HZ <br />
            Heating Power : 25 KW
            <br />
            Driven Mode : Hydraulic / Air Compressor - 7HP
            <br />
            Air Pressure : 0.5*0.8 MPa20.5m'/minute
            <br />
            Material Output : 3~12kg/minute
            <br />
            Unilateral Pressure : 36Мpa
            <br />
            AB Material Ratio : 1:1
            <br />
            Hydraulic Pressure : 6-18Mpa <br />
            Materials : Heated PU and Thermo plastic coatings
          </p>
        </div>
      </div>


      <div className="ps_cards">
        {/* CARD */}
        <div className="s_card"   id="last">
          <h3> AIR ASSISTED SPRAYER</h3>
          <img src={X360} alt="SprayX Fast shot xpro" />
          <h3>SprayX - 360</h3>
          <p>
          Output pressure(MPa) : 16-20  (160~200Kg/cm²) <br/>
          Inlet pressure(MPa) : 0.3-0.6<br/>
          Mixing ratio : 1:1<br/>
          Stroke(mm) : 80<br/>
            Materials : Single Component
          </p>
        </div>

        <div className="s_card"   id="last">
        <h3> AIR ASSISTED SPRAYER</h3>
          <img id="pneumatic_imgs" src={X540} alt="SprayX 1080" />
          <h3>SprayX - 540</h3>
          <p>
          Output pressure(MPa) : 20-27.6  (200~276Kg/cm²) <br/>
          Inlet pressure(MPa) : 0.3-0.6<br/>
          Mixing ratio : 1:1<br/>
          Stroke(mm) : 100<br/>
            Materials : Plural Component
            <br />
          </p>
        </div>

        <div className="s_card"  id="last">
        <h3> AIR ASSISTED SPRAYER</h3>

          <img id="pneumatic_imgs" src={X720} alt="SprayX 9000" />
          <h3>SprayX - 720</h3>
          <p>
          Output pressure(MPa) : 20-27.6  (200~276Kg/cm²) <br/>
          Inlet pressure(MPa) : 0.3-0.6<br/>
          Mixing ratio : 1:4<br/>
          Stroke(mm) : 100<br/>
            Materials : Plural Component
          </p>
        </div>
      </div>
    </div>
  );
}

export default SprayMachines;
